import { Injectable } from '@angular/core';
import moment from 'moment';
import { UUID } from 'short-uuid';

import { TRIGGER_TYPE_KIND } from '@http/message/message.constants';
import { MessageModel } from '@http/message/message.model';
import {
  AutoMessageTriggerTypeExternal,
  OpenedSdkPageTriggerTypeExternal,
  OpenedWebPageTriggerTypeExternal,
} from '@http/message/trigger.types';
import { MessagePartModel } from '@http/message-part/message-part.model';
import { Properties } from '@http/property/property.model';
import { UserTag } from '@http/user/types/user.type';
import { TriggerChainStepExternal } from '@panel/app/http/trigger-chain/external-types';
import { TriggerChainStep } from '@panel/app/http/trigger-chain/internal-types';
import { UrlFilterMapper } from '@panel/app/partials/url-filter-configurator/url-filter-mapper';
import { FilterParser } from '@panel/app/services/filter/filter.parser';
import { FilterAjsModel } from '@panel/app/services/filter-ajs/filter-ajs.model';
import { ExternalFiltersAJS } from '@panel/app/services/filter-ajs/filter-ajs.types';
import { DistributivePick } from '@panel/app/shared/types/distributive-pick.type';
import { TIME_UNITS } from '@panel/app-old/shared/services/time-unit/time-unit.constants';
import { TimeUnitService } from '@panel/app-old/shared/services/time-unit/time-unit.service';

/**
 * Это волшебное число, которое надо отправлять на бэкенд, чтоб повторная отправка не отрабатывала;
 */
const REPEAT_DISABLE_MAGICAL_NUMBER = 1000000000;

export type TriggerChainParseToInternalExtraData = {
  properties: Properties;
  userTags: UserTag[];
};

function getNextStep(nextSteps: UUID[]): UUID | null {
  if (nextSteps.length > 1) {
    throw new Error('There must be only one nextStep');
  }
  return nextSteps[0] ?? null;
}

@Injectable({ providedIn: 'root' })
export class TriggerChainStepMapper {
  constructor(
    private readonly messagePartModel: MessagePartModel,
    private readonly messageModel: MessageModel,
    private readonly timeUnitService: TimeUnitService,
    private readonly filterAjsModel: FilterAjsModel,
    private readonly filterParser: FilterParser,
  ) {}

  /**
   * Стоить иметь в виду, что весь content из метадаты приходит строками, чисел там быть не может
   * Поэтому в некоторых местах есть преобразования строк в числа
   */
  private parseMetadataToInternal(
    { type, metadata }: TriggerChainStepExternal,
    { properties, userTags }: TriggerChainParseToInternalExtraData,
  ): DistributivePick<TriggerChainStep, 'type' | 'meta'> {
    switch (type) {
      case 'auto_message':
        return {
          type: 'autoMessage',
          meta: {
            previewImage: metadata.content.previewData
              ? {
                  url: metadata.content.previewData.url,
                  heightPx: +metadata.content.previewData.height,
                }
              : {
                  url: '',
                  heightPx: 0,
                },
            message: {
              messageId: metadata.content.messageId,
              parts: metadata.content.parts.map((part) =>
                this.messagePartModel.parseMessagePartToInternalFormat(part, properties),
              ),
            },
            nextStep: getNextStep(metadata.content.nextSteps),
          },
        };
      case 'delay':
        return {
          type,
          meta: {
            waitForDate: metadata.content.waitForDate ? moment.unix(metadata.content.waitForDate).unix() : null,
            delay: {
              time: metadata.content.seconds ?? 5,
              unit: this.timeUnitService.getByValue(metadata.content.seconds ?? 5, [
                // Массив единиц времени взят из conditions-sending.model.ts:57
                TIME_UNITS.SECOND,
                TIME_UNITS.MINUTE,
                TIME_UNITS.HOUR,
                TIME_UNITS.DAY,
              ]),
            },
            propertyName: metadata.content.propertyName ?? null,
            nextStep: getNextStep(metadata.content.nextSteps),
            nextStepOnFail: metadata.content.nextStepOnFailure,
          },
        };
      case 'filter':
        return {
          type,
          meta: {
            filters: this.filterParser.parseToInternal(metadata.content.filters),
            nextStep: getNextStep(metadata.content.nextSteps),
            nextStepOnFail: metadata.content.nextStepOnFailure,
          },
        };
      case 'reaction':
        return {
          type,
          meta: {
            event: metadata.content.eventType,
            reactionTime: {
              time: metadata.content.reactionTime,
              unit: this.timeUnitService.getByValue(metadata.content.reactionTime, [
                // Массив единиц времени взят из conditions-sending.model.ts:57
                TIME_UNITS.SECOND,
                TIME_UNITS.MINUTE,
                TIME_UNITS.HOUR,
                TIME_UNITS.DAY,
              ]),
            },
            reactionType: metadata.content.reactionType,
            nextStep: getNextStep(metadata.content.nextSteps),
            nextStepOnFail: metadata.content.nextStepOnFailure,
          },
        };
      case 'sending_conditions':
        const fixedTriggerTypes = metadata.content.triggerTypes.map((t) => ({ ...t, kind: Number(t.kind) }));

        return {
          type: 'sendingConditions',
          meta: {
            triggers: metadata.content.eventTypes,
            filters: this.filterAjsModel.linkWithPropsAndTags(metadata.content.filters, properties, userTags),

            delay: {
              isEnabled: metadata.content.afterDelay !== null,
              value:
                metadata.content.afterDelay !== null
                  ? {
                      time: metadata.content.afterDelay,
                      unit: this.timeUnitService.getByValue(metadata.content.afterDelay, [
                        ...Object.values(TIME_UNITS),
                      ]),
                    }
                  : { time: 5, unit: TIME_UNITS.SECOND },
            },
            userStatuses: metadata.content.userStatuses,
            nextStep: getNextStep(metadata.content.nextSteps),
            triggerTypes: this.messageModel.parseTriggerTypeToInternalFormat(fixedTriggerTypes),
            sendingRepeat: {
              isEnabled: Number(metadata.content.repeatDelay) !== REPEAT_DISABLE_MAGICAL_NUMBER,
              repeatDelay:
                Number(metadata.content.repeatDelay) === REPEAT_DISABLE_MAGICAL_NUMBER
                  ? 60 * 60
                  : metadata.content.repeatDelay,
              notSendReplied: metadata.content.notSendReplied ?? false,
            },
            sendingTime: this.messageModel.parseSendTimeToInternal(metadata.content),
          },
        };
      case 'exit':
        return {
          type,
          meta: {
            type: metadata.content.type === 'finished' ? 'success' : 'exit',
          },
        };
    }
  }

  parseToInternal(
    triggerChainStepExternal: TriggerChainStepExternal,
    extraData: TriggerChainParseToInternalExtraData,
  ): TriggerChainStep {
    return {
      id: triggerChainStepExternal.id,
      name: triggerChainStepExternal.name,
      coordinates: {
        x: Number(triggerChainStepExternal.metadata.coordinates.x),
        y: Number(triggerChainStepExternal.metadata.coordinates.y),
      },
      uuid: triggerChainStepExternal.metadata.uuid,
      ...this.parseMetadataToInternal(triggerChainStepExternal, extraData),
    };
  }

  private parseMetadataToExternal({
    coordinates,
    uuid,
    type,
    meta,
  }: TriggerChainStep): DistributivePick<TriggerChainStepExternal, 'type' | 'metadata'> {
    switch (type) {
      case 'autoMessage':
        return {
          type: 'auto_message',
          metadata: {
            coordinates: coordinates,
            uuid: uuid,
            content: {
              previewData: meta.previewImage.url
                ? {
                    url: meta.previewImage.url,
                    height: +meta.previewImage.heightPx,
                  }
                : null,
              messageId: meta.message.messageId,
              parts: meta.message.parts.map((part) => this.messagePartModel.parseMessagePartToServerFormat(part)),
              nextSteps: meta.nextStep === null ? [] : [meta.nextStep],
            },
          },
        };
      case 'delay':
        return {
          type,
          metadata: {
            coordinates: coordinates,
            uuid: uuid,
            content: {
              propertyName: meta.propertyName ?? null,
              waitForDate: meta.waitForDate ? moment.unix(meta.waitForDate).utc().unix() : null,
              seconds: meta.waitForDate ? null : meta.delay.time,
              nextSteps: meta.nextStep === null ? [] : [meta.nextStep],
              nextStepOnFailure: meta.nextStepOnFail,
            },
          },
        };
      case 'filter':
        return {
          type,
          metadata: {
            coordinates: coordinates,
            uuid: uuid,
            content: {
              filters: this.filterParser.parseToExternal(meta.filters),
              nextStepOnFailure: meta.nextStepOnFail,
              nextSteps: meta.nextStep === null ? [] : [meta.nextStep],
            },
          },
        };
      case 'reaction':
        return {
          type,
          metadata: {
            coordinates: coordinates,
            uuid: uuid,
            content: {
              eventType: meta.event,
              reactionTime: meta.reactionTime.time,
              reactionType: meta.reactionType,
              nextSteps: meta.nextStep === null ? [] : [meta.nextStep],
              nextStepOnFailure: meta.nextStepOnFail,
            },
          },
        };
      case 'sendingConditions':
        const parsedTriggerTypes = this.messageModel.parseTriggerTypesToServeFormat(meta.triggerTypes);
        const triggerTypesAreOpenedPage = parsedTriggerTypes.every(
          (
            t: AutoMessageTriggerTypeExternal,
          ): t is OpenedWebPageTriggerTypeExternal | OpenedSdkPageTriggerTypeExternal =>
            t.kind === TRIGGER_TYPE_KIND.URL || t.kind === TRIGGER_TYPE_KIND.SDK_PAGE,
        );

        return {
          type: 'sending_conditions',
          metadata: {
            coordinates: coordinates,
            uuid: uuid,
            content: {
              eventTypes: meta.triggers,
              // Кастуем типы, потому что модель фильтров говно и надо переписывать
              filters: this.filterAjsModel.parseToServerFormat(meta.filters, false) as ExternalFiltersAJS,
              userStatuses: meta.userStatuses,
              afterDelay: meta.delay.isEnabled ? meta.delay.value.time : null,
              triggerTypes: parsedTriggerTypes,
              nextSteps: meta.nextStep === null ? [] : [meta.nextStep],
              ...this.messageModel.parseSendTimeToExternal(meta.sendingTime),
              repeatDelay: meta.sendingRepeat?.isEnabled
                ? meta.sendingRepeat.repeatDelay
                : REPEAT_DISABLE_MAGICAL_NUMBER,
              notSendReplied: meta.sendingRepeat?.isEnabled ? meta.sendingRepeat.notSendReplied : null,
              sendingFilters: triggerTypesAreOpenedPage
                ? UrlFilterMapper.externalSendingFiltersForOpenedPageTriggerTypes(parsedTriggerTypes)
                : null,
            },
          },
        };
      case 'exit':
        return {
          type,
          metadata: {
            coordinates: coordinates,
            uuid: uuid,
            content: {
              type: meta.type === 'success' ? 'finished' : 'dropped_off',
            },
          },
        };
    }
  }

  parseToExternal(triggerChainStep: TriggerChainStep): TriggerChainStepExternal {
    // Распаковать type и content из triggerChainStepExternal не получится,
    // потому что TS потеряется и начнет показывать ошибку
    return {
      id: triggerChainStep.id,
      name: triggerChainStep.name,
      ...this.parseMetadataToExternal(triggerChainStep),
    };
  }

  parseToTemplate(triggerChainStep: TriggerChainStep): TriggerChainStepExternal {
    return {
      name: triggerChainStep.name,
      ...this.parseMetadataToExternal(triggerChainStep),
    };
  }
}
