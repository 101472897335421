import cloneDeep from 'lodash-es/cloneDeep';
import moment from 'moment';
import Moment from 'moment';

import { USER_STATUSES_FOR_SENDING } from '@http/message/message.constants';
import { DeleteMessage } from '@http/message/message.types';
import {
  DefaultSendingUrlFilterType,
  SendingUrlFiltersGroupTypesWithoutNoType,
  SendingUrlFiltersSettingsType,
  SendingUrlFilterType,
} from '@panel/app/services/conditions-sending/conditions-sending.types';
import { TIME_UNIT_MEASURES, TIME_UNITS } from '@panel/app-old/shared/services/time-unit/time-unit.constants';

import {
  AFTER_TIME_MAX_VALUE,
  DELETE_MESSAGE_MAX_INTERVAL,
  GOAL_EVENT_MAX_TIMEOUT,
  REPEAT_MAX_DELAY,
  SENDING_FILTERS_GROUP_TYPES,
  URL_FILTERS_OPERATIONS,
} from './conditions-sending.constants';

export class ConditionsSendingModel {
  /**
   * Дефолтный фильтр по URL, на котором будет срабатывать сообщение
   *
   * @type {Object}
   */
  static DEFAULT_SENDING_URL_FILTER: DefaultSendingUrlFilterType = {
    exclusion: {
      property_name: '$url',
      type: URL_FILTERS_OPERATIONS.URL_NOT_EQ,
      value: {
        value: '',
      },
    },
    inclusion: {
      property_name: '$url',
      type: URL_FILTERS_OPERATIONS.URL_EQ,
      value: {
        value: '',
      },
    },
  };

  static DEFAULT_CONDITIONS = {
    parts: [], // ВЫБОР ТРИГГЕРА
    triggers: [], // после каких событий на сайте отправлять автосообщение
    triggerTypes: {
      openedWebPageTriggers: [],
      openedSdkPageTriggers: [],
      leaveSiteAttemptTrigger: false,
    },
    isAfterTime: false, // Отправлять с задержкой или нет
    afterTimeValue: 5 * TIME_UNIT_MEASURES[TIME_UNITS.SECOND], // время после события после которого покажется автосообщение
    afterTimeMaxValue: AFTER_TIME_MAX_VALUE * TIME_UNIT_MEASURES[TIME_UNITS.DAY], // максимальное допустимое время для задержки показа автосообщения
    afterTimeTimeUnit: TIME_UNITS.SECOND, // текущая единица времени, после которого покажется автосообщение
    afterTimeTimeUnits: [
      // доступные единицы времени для задержки отправки автосообщения
      TIME_UNITS.SECOND,
      TIME_UNITS.MINUTE,
      TIME_UNITS.HOUR,
      TIME_UNITS.DAY,
    ],
    isSendAtTime: false, // Отправлять в конкретное время или нет
    sendTimeValueFromH: '09',
    sendTimeValueFromM: '00',
    sendTimeValueToH: '18',
    sendTimeValueToM: '00',

    // АУДИТОРИЯ
    filters: {
      type: 'and',
      filters: {
        props: [],
        events: [],
        tags: [],
      },
    },

    // УСЛОВИЯ ОТПРАВКИ
    isRepeat: true, // тип повторной отправки
    repeatDelay: TIME_UNIT_MEASURES[TIME_UNITS.DAY], // время повторной отправки
    repeatMaxDelay: REPEAT_MAX_DELAY * TIME_UNIT_MEASURES[TIME_UNITS.DAY], // максимально допустимое время повторной отправки
    repeatDelayTimeUnit: TIME_UNITS.DAY, // текущая единица времени повторной отправки
    repeatDelayTimeUnits: [
      // доступные единицы времени для задержки отправки автосообщения
      TIME_UNITS.SECOND,
      TIME_UNITS.MINUTE,
      TIME_UNITS.HOUR,
      TIME_UNITS.DAY,
    ],
    notSendReplied: true,
    isControlGroupEnabled: false, // включена или отключена контрольная группа
    controlGroup: null, // контрольная группа
    userStatuses: USER_STATUSES_FOR_SENDING.NO_MATTER, // включена или отлючена отправка автосообщения в зависимости от статуса пользователя
    // ЦЕЛЬ
    hasGoal: false, // флаг, установлена ли цель
    goalEventType: null,
    goalEventCostType: 'none', // ценность цели, выбрано 'Не определять'
    goalEventCost: 100, // ценность цели в деньгах
    goalEventCostProp: null, // событие для ценности цели
    goalEventTimeout: 30 * TIME_UNIT_MEASURES[TIME_UNITS.DAY], // скорость дослижения цели
    goalEventMaxTimeout: GOAL_EVENT_MAX_TIMEOUT * TIME_UNIT_MEASURES[TIME_UNITS.DAY], // максимальное значение для скорости достижения цели
    goalEventTimeoutTimeUnit: TIME_UNITS.DAY, // текущая единица измерения скорости достижения цели
    goalEventTimeoutTimeUnits: [
      // доступные для выбора единицы измерения
      TIME_UNITS.MINUTE,
      TIME_UNITS.HOUR,
      TIME_UNITS.DAY,
    ],
    sendingFilters: {
      type: SENDING_FILTERS_GROUP_TYPES.NO,
      filters: [],
    },
  };

  static DEFAULT_DELETE_MESSAGE: DeleteMessage = {
    deleteType: null, // Выбранный тип удаления автосообщения
    interval: {
      value: 24 * TIME_UNIT_MEASURES[TIME_UNITS.HOUR],
      unit: TIME_UNITS.HOUR,
      units: [TIME_UNITS.SECOND, TIME_UNITS.MINUTE, TIME_UNITS.HOUR, TIME_UNITS.DAY, TIME_UNITS.MONTH],
    } /* Параметры для удаления в конкретную дату */,
    time: {
      date: null, // дата для датапикера
      datepickerOptions: {
        // опции для датапикера
        singleDatePicker: true,
        maxDate: null,
        minDate: null,
        drops: 'up',
      },
      hours: null, // часы
      minutes: null, // минуты
      time: null, // время удаления сообщения (дата + часы + минуты)
    },
  };

  /**
   * Настройки по умолчанию
   */
  static getDefault() {
    return cloneDeep(ConditionsSendingModel.DEFAULT_CONDITIONS);
  }

  /**
   * Получение дефолтного фильтра по URL, на котором будет срабатывать сообщение
   *
   * @param currentSendingFiltersType - Текущий тип фильтров по URL (отсутствует, включение или исключение)
   */
  static getDefaultSendingFilter(
    currentSendingFiltersType: SendingUrlFiltersGroupTypesWithoutNoType,
  ): SendingUrlFilterType {
    return cloneDeep(ConditionsSendingModel.DEFAULT_SENDING_URL_FILTER[currentSendingFiltersType]);
  }

  /**
   * Добавление фильтра по URL в массив фильтров сообщения
   *
   * @param setting — Фильтры по URL
   * @param currentSendingFiltersType - Текущий тип фильтров по URL (отсутствует, включение или исключение)
   */
  static addPageInSendingFilters(
    setting: SendingUrlFiltersSettingsType,
    currentSendingFiltersType: SendingUrlFiltersGroupTypesWithoutNoType,
  ): void {
    setting[currentSendingFiltersType].filters.push(
      cloneDeep(ConditionsSendingModel.getDefaultSendingFilter(currentSendingFiltersType)),
    );
  }

  /**
   * Возвращает текущее время с учетом тайм-зоны для шага с настройками триггера
   *
   * @param timeZoneOffset - Смещение тайм-зоны
   */
  static getCurrentTimeForTriggerStep(timeZoneOffset: number): Moment.Moment {
    return moment().utcOffset(timeZoneOffset);
  }

  /**
   * Параметры удаления сообщений по умолчанию
   */
  static getDefaultDeleteMessageForTriggerStep(timeZoneOffset: number): DeleteMessage {
    const currentTime = this.getCurrentTimeForTriggerStep(timeZoneOffset);
    const maxTime = this.getMaxTimeForTriggerStep(currentTime);
    const returnedDefaultDeleteMessage = cloneDeep(ConditionsSendingModel.DEFAULT_DELETE_MESSAGE);

    returnedDefaultDeleteMessage.time.date = moment(currentTime).add(1, 'days');
    returnedDefaultDeleteMessage.time.datepickerOptions.maxDate = maxTime;
    returnedDefaultDeleteMessage.time.datepickerOptions.minDate = currentTime;
    returnedDefaultDeleteMessage.time.hours = currentTime.hours();
    returnedDefaultDeleteMessage.time.minutes = currentTime.minutes();
    returnedDefaultDeleteMessage.time.time = moment(currentTime).add(1, 'days');

    return returnedDefaultDeleteMessage;
  }

  /**
   * Возвращает максимальное время для шага с настройками триггера
   *
   * @param currentTime - Текущее время
   */
  static getMaxTimeForTriggerStep(currentTime: Moment.Moment): Moment.Moment {
    return moment(currentTime).add(DELETE_MESSAGE_MAX_INTERVAL / TIME_UNIT_MEASURES[TIME_UNITS.MONTH], 'months');
  }
}
