<ng-container *transloco="let t; read: 'tcSendingConditionOverlayComponent'">
  <div
    class="d-inline-block h-100 w-100 sending-condition-overlay-popover"
    [ngbPopover]="triggersPopover"
    placement="bottom"
    triggers="manual"
    [autoClose]="true"
  ></div>

  <ng-template #triggersPopover>
    <ng-container *ngIf="_overlaySource">
      <div class="font-weight-bold mb-5">
        {{ 'triggerChainTableItemComponent.popover.triggers.heading' | transloco }}
      </div>
      <!-- Триггеры -->
      <div *ngFor="let trigger of eventTypes; trackBy: trackByTriggers">
        <i
          *ngIf="isEventRemoved(trigger)"
          class="cqi-sm cqi-triangle-exclamation text-danger"
          [ngbTooltip]="'triggerChainTableItemComponent.name.deletedEventTooltip' | transloco"
          container="body"
          placement="top"
        ></i>
        <span class="inline text-secondary">{{ getChainEventTriggerPrettyName(trigger) }}</span>
      </div>

      <div *ngIf="triggerTypes">
        <ng-container *ngIf="triggerTypes.leaveSiteAttemptTrigger">
          <div class="text-secondary">
            {{ 'triggerChainTableItemComponent.triggerTypes.leaveSiteAttemptTrigger' | transloco }}
          </div>
        </ng-container>

        <ng-container *ngIf="triggerTypes.openedWebPageTriggers.length > 0">
          <div class="text-secondary">
            {{ 'triggerChainTableItemComponent.triggerTypes.openedPage.name' | transloco }}
          </div>
          <div
            *ngFor="
              let openedPageTriggerType of triggerTypes.openedWebPageTriggers;
              trackBy: trackByOpenedWebPageTriggers
            "
          >
            <span class="inline text-secondary">
              {{ getOpenedWebPageComparisonPrettyName(openedPageTriggerType.comparison) + ':' }}
            </span>
            <span class="inline text-secondary">
              {{ openedPageTriggerType.url }}
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="triggerTypes.openedSdkPageTriggers.length > 0">
          <div class="text-secondary">
            {{ 'triggerChainTableItemComponent.triggerTypes.openedSdkPage.name' | transloco }}
          </div>
          <div
            *ngFor="
              let openedPageTriggerType of triggerTypes.openedSdkPageTriggers;
              trackBy: trackByOpenedSdkPageTriggers
            "
          >
            <span class="inline text-secondary">
              {{ getOpenedSdkPageComparisonPrettyName(openedPageTriggerType.comparison) + ':' }}
            </span>
            <span class="inline text-secondary">
              {{ openedPageTriggerType.sdkPage }}
            </span>
          </div>
        </ng-container>
      </div>

      <div *ngIf="filters">
        <hr />

        <div class="font-weight-bold mb-5">
          {{ 'triggerChainTableItemComponent.popover.filters.heading' | transloco }}
        </div>

        <div class="text-secondary">
          <span *ngIf="filters.type === 'and'">{{
            'triggerChainTableItemComponent.popover.filters.satisfies.allFilters' | transloco
          }}</span>
          <span *ngIf="filters.type === 'or'">{{
            'triggerChainTableItemComponent.popover.filters.satisfies.anyOfFilters' | transloco
          }}</span>
        </div>

        <div *ngFor="let filter of filters.filters.props; first as isFirst">
          <div
            *ngIf="isFirst"
            class="text-secondary"
          >
            {{ 'triggerChainTableItemComponent.popover.filters.properties' | transloco }}
          </div>
          <span class="text-secondary">{{ filter.userProp.prettyName }}</span>
          <cq-message-filters
            class="d-inline text-secondary"
            [filter]="filter"
          ></cq-message-filters>
        </div>

        <div *ngFor="let filter of filters.filters.events; first as isFirst">
          <div
            *ngIf="isFirst"
            class="text-secondary"
          >
            {{ 'triggerChainTableItemComponent.popover.filters.events' | transloco }}
          </div>
          <span class="text-secondary">{{ filter.prettyName }}</span>
          <cq-message-filters
            class="d-inline text-secondary"
            [filter]="filter"
          ></cq-message-filters>
        </div>

        <span
          class="no-space"
          *ngFor="let tag of filters.filters.tags; first as isFirst; last as isLast"
        >
          <div
            *ngIf="isFirst"
            class="mt-10 text-secondary"
          >
            {{ 'triggerChainTableItemComponent.popover.filters.tags' | transloco }}
          </div>
          <cq-user-tag [tag]="tag.tag"></cq-user-tag>
          <span *ngIf="isLast">,&nbsp;</span>
        </span>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
