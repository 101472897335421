<!--NOTE подписка на емейл работает по-другому-->
<ng-container *ngIf="filter.userProp || filter.tag">
  <span *ngIf="filter.userProp && filter.userProp.name === '$email_status'">
    <span>-</span>
    <span *ngIf="filter.type === 'str_neq'">{{ 'models.emailStatus.pseudoStatuses.not_subscribed' | transloco }} </span>
    <span *ngIf="filter.type === 'str_eq'">{{ 'models.emailStatus.statuses.' + filter.value.value | transloco }} </span>
  </span>

  <span
    *ngIf="filter.tag || (filter.userProp && filter.userProp.name !== '$email_status')"
    [ngSwitch]="filter.type"
  >
    <span *ngSwitchCase="'known'">{{ 'messageFiltersComponent.known' | transloco }}</span>
    <span *ngSwitchCase="'unknown'">{{ 'messageFiltersComponent.unknown' | transloco }}</span>
    <span *ngSwitchCase="'gt'">{{
      'messageFiltersComponent.moreThan' | transloco: { value: filter.value.value }
    }}</span>
    <span *ngSwitchCase="'lt'">{{
      'messageFiltersComponent.lessThan' | transloco: { value: filter.value.value }
    }}</span>
    <span *ngSwitchCase="'gt_or_unknown'">{{
      'messageFiltersComponent.moreOrUnknown' | transloco: { value: filter.value.value }
    }}</span>
    <span *ngSwitchCase="'lt_or_unknown'">{{
      'messageFiltersComponent.lessOrUnknown' | transloco: { value: filter.value.value }
    }}</span>
    <span *ngSwitchCase="'eq'">{{ 'messageFiltersComponent.equal' | transloco: { value: filter.value.value } }}</span>
    <span *ngSwitchCase="'neq'">{{
      'messageFiltersComponent.noEqual' | transloco: { value: filter.value.value }
    }}</span>
    <span *ngSwitchCase="'range'">{{
      'messageFiltersComponent.range' | transloco: { from: filter.value.value1, to: filter.value.value2 }
    }}</span>
    <span *ngSwitchCase="'str_contains'">
      <span>{{ 'messageFiltersComponent.contains' | transloco }}</span>
      <span>{{ filter.value.value }}</span>
    </span>
    <span *ngSwitchCase="'str_notcontains'">
      <span>{{ 'messageFiltersComponent.notContains' | transloco }}</span>
      <span>{{ filter.value.value }}</span>
    </span>
    <span *ngSwitchCase="'str_eq'">{{
      'messageFiltersComponent.equal' | transloco: { value: filter.value.value }
    }}</span>
    <span *ngSwitchCase="'str_neq'">{{
      'messageFiltersComponent.noEqual' | transloco: { value: filter.value.value }
    }}</span>
    <span *ngSwitchCase="'daysless'">
      <span>{{ 'messageFiltersComponent.date.less' | transloco }}</span>
      <span>{{ 'messageFiltersComponent.date.' + filter.value.unit | transloco: { value: filter.value.value } }}</span>
    </span>
    <span *ngSwitchCase="'daysmore'">
      <span>{{ 'messageFiltersComponent.date.more' | transloco }}</span>
      <span>{{ 'messageFiltersComponent.date.' + filter.value.unit | transloco: { value: filter.value.value } }}</span>
    </span>
    <span *ngSwitchCase="'daysless_or_unknown'">
      <span>{{ 'messageFiltersComponent.date.lessOrUnknown' | transloco }}</span>
      <span>{{ 'messageFiltersComponent.date.' + filter.value.unit | transloco: { value: filter.value.value } }}</span>
    </span>
    <span *ngSwitchCase="'daysmore_or_unknown'">
      <span>{{ 'messageFiltersComponent.date.moreOrUnknown' | transloco }}</span>
      <span>{{ 'messageFiltersComponent.date.' + filter.value.unit | transloco: { value: filter.value.value } }}</span>
    </span>
    <span *ngSwitchCase="'bool_true'">{{ 'messageFiltersComponent.true' | transloco }}</span>
    <span *ngSwitchCase="'bool_false'">{{ 'messageFiltersComponent.false' | transloco }}</span>
    <span *ngSwitchCase="'lcontains'">
      <span>{{ 'messageFiltersComponent.contains' | transloco }}</span>
      <cq-user-tag
        *ngIf="filter.tag"
        [tag]="filter.tag"
      ></cq-user-tag>
      <span *ngIf="!filter.tag">{{ filter.value.value }}</span>
    </span>
    <span *ngSwitchCase="'lnotcontains'">
      <span>{{ 'messageFiltersComponent.notContains' | transloco }}</span>
      <cq-user-tag
        *ngIf="filter.tag"
        [tag]="filter.tag"
      ></cq-user-tag>
      <span *ngIf="!filter.tag">{{ filter.value.value }}</span>
    </span>
    <span *ngSwitchCase="'lcontains_any'">
      <span>{{ 'messageFiltersComponent.containsAny' | transloco }}</span>
      <span>{{ filter.value.value }}</span>
    </span>
    <span *ngSwitchCase="'lnotcontains_any'">
      <span>{{ 'messageFiltersComponent.notContainsAny' | transloco }}</span>
      <span>{{ filter.value.value }}</span>
    </span>
    <span *ngSwitchCase="'lcontains_all'">
      <span>{{ 'messageFiltersComponent.containsAll' | transloco }}</span>
      <span>{{ filter.value.value }}</span>
    </span>
    <span *ngSwitchCase="'lnotcontains_all'">
      <span>{{ 'messageFiltersComponent.notContainsAll' | transloco }}</span>
      <span>{{ filter.value.value }}</span>
    </span>
  </span>
</ng-container>
