<ng-container *transloco="let t; read: 'triggerChainFilterEditorComponent'">
  <ng-container
    *tuiLet="{
      app: triggerChainEditorStore.currentApp$ | async,
      properties: triggerChainEditorStore.properties$ | async,
      telegramIntegrations: triggerChainEditorStore.telegramIntegrations$ | async,
    } as params"
  >
    <ng-container *ngIf="params.app && params.properties && params.telegramIntegrations">
      <cq-trigger-chain-step-editor-wrap
        [formControl]="form.controls.name"
        (deleteBlockClick)="deleteStepChain()"
        (copyBlockClick)="copyStepChain()"
      >
        <div class="p-20">
          <div class="text-secondary mb-10">{{ t('logicalOperationSwitcher.description') }}</div>
          <div class="btn-group btn-block btn-group-toggle mb-25">
            <label
              class="btn btn-outline-primary"
              [class.active]="form.controls.logicalOperation.value === 'and'"
            >
              <input
                [formControl]="form.controls.logicalOperation"
                type="radio"
                value="and"
              />
              <span>{{ t('logicalOperationSwitcher.and') }}</span>
            </label>
            <label
              class="btn btn-outline-primary"
              [class.active]="form.controls.logicalOperation.value === 'or'"
            >
              <input
                [formControl]="form.controls.logicalOperation"
                type="radio"
                value="or"
              />
              <span>{{ t('logicalOperationSwitcher.or') }}</span>
            </label>
          </div>

          <div [cqShowError]="form">
            <cq-property-filters
              [app]="params.app"
              [props]="params.properties.userProps"
              [telegramIntegrations]="params.telegramIntegrations"
              [formControl]="form.controls.propertyFilters"
            ></cq-property-filters>

            <cq-validation-messages [control]="form">
              <cq-validation-message errorName="filtersAreEmpty">{{ t('errors.required') }}</cq-validation-message>
            </cq-validation-messages>
          </div>

          <hr />
          <div [cqShowError]="form">
            <cq-event-filters
              [app]="params.app"
              [events]="params.properties.eventTypes"
              [formControl]="form.controls.eventFilters"
            ></cq-event-filters>

            <cq-validation-messages [control]="form">
              <cq-validation-message errorName="filtersAreEmpty">{{ t('errors.required') }}</cq-validation-message>
            </cq-validation-messages>
          </div>
        </div>
      </cq-trigger-chain-step-editor-wrap>
    </ng-container>
  </ng-container>
</ng-container>
