/**
 * Получение типов через сколько можно удалить отправленное сообщение
 */
export enum MESSAGE_DELETING_TYPES {
  CERTAIN_DATE = 'certain_date', // Конкретная дата в которую удалится сообщение (в формате moment)
  TIME_INTERVAL = 'time_interval', // Интервал времени через который удалится сообщение
}

/**
 * Получение типов страниц, которые работают с сообщениями (авто-сообщениями)
 *
 * NOTE: Необходимо для сущностей, основанных на сообщениях (авто-сообщениях), чтобы разделить контекст (работы, переводов)
 */
export enum MESSAGE_PAGE_TYPES {
  AUTO_MESSAGES = 'autoMessages', // Страница "Автосообщения"
  CHAT_BOTS = 'chatBots', // Страница "Чат-боты"
}

/**
 * Получение статусов сообщения
 */
export enum MESSAGE_STATUSES {
  ALL = 'all', // все
  ACTIVE = 'active', // активные
  STOPPED = 'stopped', // приостановленные
}

/**
 * Получение типов сообщения
 */
export enum MESSAGE_TYPES {
  AUTO = 'auto', // автосообщение
  CHAT_BOT = 'chat_bot', // Чат-бот
  MANUAL = 'manual', // ручное сообщение
}

/**
 * Получение статусов рассылок
 *
 * @return {Object}
 */
export enum SENDING_STATUSES {
  ACTIVE = 'active', // в процессе
  CANCELED = 'canceled', // отменена
  ERROR = 'error', // ошибка
  FINISHED = 'finished', // завершена
  PAUSED = 'paused', // приостановлена
  PLANNED = 'planned', // запланирована
}

/**
 * Получение типов действий получателей сообщения
 *
 * @returns {Object}
 */
export enum SENDING_TYPES {
  BOUNCED = 'bounced', // bounced (по какой-то причине не доставлено)
  CLICKED = 'clicked', // перешёл по ссылке
  GOAL_COMPLETED = 'goal_completed', // достиг цели
  READ = 'read', // прочитал сообщение
  REPLIED = 'replied', // ответил на сообщение
  SENDED = 'sended', // отправлено сообщений
  SPAM = 'spam', // пожаловался на спам
  UNSUBSCRIBED = 'unsubscribed', // отписался
}

/**
 * Получение статусов пользователя для отправки автосообщения по этим статусам
 */
export const USER_STATUSES_FOR_SENDING = {
  NO_MATTER: null, // Не важно какой статус
  ONLINE_OR_IDLE: 'online_or_idle', // Онлайн или спит
  OFFLINE: 'offline', // Оффлайн
} as const;

export enum TRIGGER_TYPE_KIND {
  URL = 0,
  LEAVE_SITE_ATTEMPT = 1,
  EVENT_TYPE = 2,
  BOT_MANUAL_START = 3,
  BOT_FIRST_USER_REPLY = 4,
  SDK_PAGE = 7,
}

/**
 * Максимальное значение задержки (в сек.)
 */
export const MAX_AFTER_DELAY = 2147483647;
