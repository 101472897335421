import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { isUndefined } from 'angular';
import filter from 'lodash-es/filter';

import { PSEUDO_DIRECTORY_TYPES, SYSTEM_DIRECTORIES } from '@http/message-directory/message-directory.constants';
import { MESSAGE_PART_TYPE_ICON_CLASSES, MESSAGE_PART_TYPES } from '@http/message-part/message-part.constants';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-auto-message-table',
  templateUrl: './auto-message-table.component.html',
  styleUrls: ['./auto-message-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoMessageTableComponent {
  @Input()
  accessToSelectedAutoMessages!: ProductFeatureAccess;

  @Input()
  accessToTelegramAutoMessages!: ProductFeatureAccess;

  @Input()
  activeMessagesAmounts!: any;

  @Input()
  appBlocks: any;

  @Input()
  currentApp: any;

  @Input()
  isAllMessageSelected: any;

  @Input()
  messages: any;

  @Output()
  clickActivateButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clickArchiveButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clickChangeDirectoryButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clickCopyButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clickPauseButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clickReestablishButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clickRenameButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  selectAllMessage: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  selectMessage: EventEmitter<any> = new EventEmitter<any>();

  protected MESSAGE_PART_TYPE_ICON_CLASSES: any = MESSAGE_PART_TYPE_ICON_CLASSES;
  protected MESSAGE_PART_TYPES: any = MESSAGE_PART_TYPES;
  protected PSEUDO_DIRECTORY_TYPES: any = PSEUDO_DIRECTORY_TYPES;
  protected SYSTEM_DIRECTORIES: any = SYSTEM_DIRECTORIES;

  constructor(
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly paywallService: PaywallService,
    protected readonly planFeatureAccessService: PlanFeatureAccessService,
    protected readonly translocoService: TranslocoService,
    protected readonly cdr: ChangeDetectorRef,
  ) {}

  /**
   * Получение отформатированного названия события триггера у сообщения
   *
   * @param event Событие-триггер
   * @returns {string}
   */
  getMessageEventTriggerPrettyName(event: any): string {
    if (this.isMessageEventTriggerRemoved(event)) {
      return `${this.translocoService.translate('general.deletedEvent')}`;
    }

    return `${event.prettyName}`;
  }

  getOpenedWebPageComparisonPrettyName(comparison: any): string {
    return this.translocoService.translate(`autoMessages.table.triggerTypes.openedWebPage.${comparison}`);
  }

  getOpenedSdkPageComparisonPrettyName(comparison: any): string {
    return this.translocoService.translate(`autoMessages.table.triggerTypes.openedSdkPage.${comparison}`);
  }

  /**
   * Заблокирована ли отправка имейлов пользователем по причине подозрительной активности
   *
   * @returns {boolean}
   */
  isEmailBlockedDueToSuspiciousActivity(message: any): boolean {
    return message.type === 'email' && (this.appBlocks.blockAllEmails || this.appBlocks.blockEmails);
  }

  /**
   * Является ли триггер сообщения удалённым
   *
   * @param event Событие-триггер
   * @returns {Boolean}
   */
  isMessageEventTriggerRemoved(event: any): boolean {
    return !event?.visible || isUndefined(event);
  }

  /**
   * Проверка есть ли у автосообщения удаленные теги
   *
   * @param tags Массив тегов из фильтров автосообщения
   * @returns {boolean}
   */
  isMessageHaveDeletedTags(tags: any): boolean {
    return filter(tags, (tag) => tag.tag.removed).length > 0;
  }

  /**
   * Проверка наличия фильтров в автосообщении
   *
   * @param message Автосообщение
   * @returns {boolean}
   */
  isMessageHaveFilters(message: any): boolean {
    return (
      message.filters.filters.props.length > 0 ||
      message.filters.filters.events.length > 0 ||
      message.filters.filters.tags.length > 0
    );
  }

  /**
   * Проверка на наличие фильтров по URL в автосообщении
   *
   * @param message - Автосообщение
   * @returns {boolean}
   */
  isMessageHaveFiltersByUrl(message: any): boolean {
    return !!message.sendingFilters?.filters.length;
  }

  /**
   * Проверка на наличие новых триггеров в автосообщении
   *
   * @param openedWebPageTriggers - Триггеры открытой страницы
   * @returns {Boolean}
   */
  isMessageHaveOpenedPageTriggerTypes(openedWebPageTriggers: any): boolean {
    return openedWebPageTriggers.length > 0;
  }

  /**
   * Проверка на наличие доп параметров в автосообщение для включения поповера
   *
   * @param message - Автосообщение
   * @returns {Boolean}
   */
  isMessageTriggersPopoverEnabled(message: any): boolean {
    return (
      message.triggers.length > 1 ||
      this.isMessageHaveFilters(message) ||
      this.isMessageHaveFiltersByUrl(message) ||
      this.isMessageHaveOpenedPageTriggerTypes(message.triggerTypes.openedWebPageTriggers) ||
      this.isMessageHaveOpenedPageTriggerTypes(message.triggerTypes.openedSdkPageTriggers)
    );
  }

  /**
   * Открыть одно из биллинговых модальных сообщений
   * @param message
   */
  openSomeAutoMessageBillingModal(message: any) {
    if (message.type === MESSAGE_PART_TYPES.TELEGRAM) {
      this.paywallService.showPaywallForAccessDenial(this.currentApp, this.accessToTelegramAutoMessages.denialReason);
      return;
    }

    this.paywallService.showAutoMessageTotalPaywall(
      this.currentApp,
      this.accessToSelectedAutoMessages.denialReason,
      1,
      this.activeMessagesAmounts,
    );
  }

  /**
   * Трек клика на открытие статистики по сообщению
   */
  trackClickOnOpenStatistics(): void {
    this.carrotquestHelper.track('Автосообщения - клик на "Статистика"');
  }

  onClickDropdown(): void {
    this.cdr.detectChanges();
  }
}
