import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiLetModule } from '@taiga-ui/cdk';

import { TriggerChainStepEditorWrapModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/shared/trigger-chain-step-editor-wrap/trigger-chain-step-editor-wrap.module';
import { PropertyFilterModule } from '@panel/app/partials/filters/components/property-filter/property-filter.module';
import { EventFiltersModule } from '@panel/app/partials/filters/filters/event-filters/event-filters.module';
import { PropertyFiltersModule } from '@panel/app/partials/filters/filters/property-filters/property-filters.module';
import { TimeSelectorModule } from '@panel/app/partials/message-editor/shared/time-selector/time-selector.module';
import { DirectivesModule } from '@panel/app/shared/directives/directives.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { TriggerChainFilterEditorComponent } from './trigger-chain-filter-editor.component';

@NgModule({
  declarations: [TriggerChainFilterEditorComponent],
  imports: [
    CommonModule,
    TimeSelectorModule,
    TriggerChainStepEditorWrapModule,
    TranslocoModule,
    ReactiveFormsModule,
    PropertyFilterModule,
    PropertyFiltersModule,
    EventFiltersModule,
    TuiLetModule,
    DirectivesModule,
    SharedModule,
  ],
  exports: [TriggerChainFilterEditorComponent],
})
export class TriggerChainFilterEditorModule {}
