(function () {
  'use strict';

  require('./audience.controller');

  angular.module('myApp.autoMessages').component('cqAutoMessageAudience', cqAutoMessageAudience());

  function cqAutoMessageAudience() {
    return {
      bindings: {
        currentApp: '=', // Текущий апп
        currentMessage: '=', // Сообщение
        disableControlGroup: '=?',
        customSelectDropdownClass: '=?',
        filtersForm: '=', // Объект формы фильтров
        hideForCanvasBot: '=?', // Скрывать элементы если компонент используется в чат-боте на канвасе
        isHideForNewOnboarding: '=?', // Флаг для скрывания элементов для нового онбординга
        messagePageType: '=?', // Тип страницы, на котором размещен компонент, необходимо для корректного трекинга событий и переводов
        properties: '=', // Свойства и типы событий
        segments: '=', // Сегменты
        showedSendingFiltersPopoverInAudience: '=', // Статус показа поповера с условиями отправки по URL в "Аудитория"
        telegramIntegrations: '=',
        tags: '=', // Теги
      },
      controller: 'CqAutoMessageAudienceController',
      controllerAs: 'vm',
      template: require('./audience.html'),
    };
  }
})();
