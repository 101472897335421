import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { TriggerChainStepAutoMessage } from '@http/trigger-chain/internal-types';
import { TriggerChainStepEditorInteractionsService } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/trigger-chain-step-editor-interactions.service';
import { TriggerChainEditorStore } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.store';
import { TriggerChainContentEditorModal } from '@panel/app-old/components/trigger-chains/content-editor/modal/trigger-chain-content-editor.modal';

@Component({
  selector: 'cq-trigger-chain-automessage-editor',
  templateUrl: './trigger-chain-automessage-editor.component.html',
  styleUrls: ['./trigger-chain-automessage-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainAutomessageEditorComponent implements OnInit {
  @Input({ required: true })
  autoMessageStep!: TriggerChainStepAutoMessage;

  @Output()
  autoMessageStepChange: Subject<TriggerChainStepAutoMessage> = new Subject<TriggerChainStepAutoMessage>();

  @Output()
  closeEditor: Subject<void> = new Subject<void>();

  constructor(
    private readonly triggerChainContentEditorModal: TriggerChainContentEditorModal,
    private readonly editorInteractions: TriggerChainStepEditorInteractionsService,
    private readonly triggerChainEditorStore: TriggerChainEditorStore,
  ) {}

  ngOnInit() {
    this.triggerChainContentEditorModal
      .openAutomessageEditorModal(this.autoMessageStep, this.editorInteractions, this.triggerChainEditorStore)
      .then((autoMessageStep) => {
        this.autoMessageStepChange.next(autoMessageStep);
        this.editorInteractions.closeClick.next();
      })
      .catch(() => {
        this.editorInteractions.closeClick.next();
      });
  }
}
