import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { TriggerChainStepFilter } from '@http/trigger-chain/internal-types';
import { triggerChainEmptyFiltersValidator } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/trigger-chain-filter-editor/trigger-chain-filter-editor.validators';
import { TriggerChainStepEditorInteractionsService } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/trigger-chain-step-editor-interactions.service';
import { TriggerChainEditorStore } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.store';
import {
  FORM_SUBMIT_SOURCE_TOKEN,
  FORM_SUBMIT_TOKEN,
} from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';
import { DestroyService } from '@panel/app/services';
import { ELASTICSEARCH_OPERATION } from '@panel/app/services/elasticsearch-operation/elasticsearch-operation.constants';
import { FILTER_EVENT_TYPE, FILTER_LOGICAL_OPERATION } from '@panel/app/services/filter/filter.constants';
import { EventFilter, PropertyFilter } from '@panel/app/services/filter/types/filter.internal-types';
import { Properties } from '@http/property/property.model';

import { FilterStepEditorForm } from './trigger-chain-filter-editor.types';

@Component({
  selector: 'cq-trigger-chain-filter-editor',
  templateUrl: './trigger-chain-filter-editor.component.html',
  styleUrls: ['./trigger-chain-filter-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainFilterEditorComponent implements OnInit {
  @Input({ required: true })
  app!: App;

  @Input({ required: true })
  isValidationStrict: boolean = false;

  @Input({ required: true })
  properties!: Properties;

  @Input({ required: true })
  get step() {
    return this._step;
  }
  set step(step: TriggerChainStepFilter) {
    this._step = step;

    this.form.setValue(
      {
        name: step.name,
        logicalOperation: step.meta.filters.logicalOperation,
        propertyFilters: step.meta.filters.filters.props,
        eventFilters: step.meta.filters.filters.events,
      },
      {
        emitEvent: false,
      },
    );
  }
  private _step!: TriggerChainStepFilter;

  readonly form: FormGroup<FilterStepEditorForm> = this.fb.group<FilterStepEditorForm>(
    {
      name: this.fb.control('', { nonNullable: true }),
      logicalOperation: this.fb.control(FILTER_LOGICAL_OPERATION.AND, { nonNullable: true }),
      propertyFilters: this.fb.control<PropertyFilter[]>(
        [
          {
            propertyName: null,
            operation: {
              type: ELASTICSEARCH_OPERATION.KNOWN,
              value: {
                value: 0,
              },
            },
          },
        ],
        { nonNullable: true },
      ),
      eventFilters: this.fb.control<EventFilter[]>(
        [
          {
            eventId: null,
            eventType: FILTER_EVENT_TYPE.COUNT,
            operation: {
              type: ELASTICSEARCH_OPERATION.KNOWN,
              value: {
                value: 0,
              },
            },
          },
        ],
        { nonNullable: true },
      ),
    },
    { validators: triggerChainEmptyFiltersValidator },
  );

  @Output()
  stepChange: Observable<TriggerChainStepFilter> = this.form.valueChanges.pipe(
    map((value) => {
      let { name, propertyFilters, eventFilters, logicalOperation } = this.form.getRawValue();

      this.step.name = name;
      this.step.meta.filters = {
        logicalOperation: logicalOperation,
        filters: {
          props: propertyFilters,
          events: eventFilters,
          tags: [],
        },
      };

      return this.step;
    }),
  );

  constructor(
    private readonly fb: FormBuilder,
    private readonly editorInteractions: TriggerChainStepEditorInteractionsService,
    protected readonly triggerChainEditorStore: TriggerChainEditorStore,
    @Inject(FORM_SUBMIT_TOKEN)
    formSubmit$: Observable<void>,
    @Inject(FORM_SUBMIT_SOURCE_TOKEN)
    private readonly formSubmitSubject: Subject<void>,
    destroy$: DestroyService,
  ) {
    formSubmit$.pipe(takeUntil(destroy$)).subscribe(() => {
      this.form.markAsTouched();
    });
  }

  ngOnInit() {
    if (this.isValidationStrict) {
      this.formSubmitSubject.next();
    }
  }

  copyStepChain() {
    this.editorInteractions.copyClick.next(this.step);
  }

  deleteStepChain() {
    this.editorInteractions.deleteClick.next(this.step);
  }
}
