<table class="trigger-messages-table table table-align-middle table-sticky-header">
  <thead>
    <tr>
      <th>
        <div class="custom-control custom-checkbox">
          <input
            class="custom-control-input"
            id="selected-messages-all"
            type="checkbox"
            (change)="selectAllMessage.emit(isAllMessageSelected)"
            [disabled]="!messages.length"
            [(ngModel)]="isAllMessageSelected"
            title="{{ 'autoMessages.table.table.headings.titleCheckbox' | transloco }}"
          />
          <label
            class="custom-control-label"
            for="selected-messages-all"
          ></label>
        </div>
      </th>
      <th>{{ 'autoMessages.table.table.headings.name' | transloco }}</th>
      <th>{{ 'autoMessages.table.table.headings.lastSent' | transloco }}</th>
      <th>{{ 'models.message.sendingTypes.sended' | transloco }}</th>
      <th>{{ 'models.message.sendingTypes.read' | transloco }}</th>
      <th>{{ 'models.message.sendingTypes.replied' | transloco }}</th>
      <th class="d-none d-xl-table-cell">{{ 'models.message.sendingTypes.clicked' | transloco }}</th>
      <th class="d-none d-xl-table-cell">{{ 'autoMessages.table.table.headings.unsubscribed' | transloco }}</th>
      <th>{{ 'models.message.sendingTypes.goal_completed' | transloco }}</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let message of messages">
      <!--CHECKBOX-->
      <td class="align-top shrink-by-content">
        <div class="custom-control custom-checkbox">
          <input
            class="custom-control-input"
            [id]="'message-checked-' + message.id"
            type="checkbox"
            (change)="selectMessage.emit()"
            [disabled]="isAllMessageSelected"
            [(ngModel)]="message.checked"
            title="{{ 'autoMessages.table.table.body.titleCheckbox' | transloco }}"
          />
          <label
            class="custom-control-label"
            [for]="'message-checked-' + message.id"
          ></label>
        </div>
      </td>

      <!--НАЗВАНИЕ-->
      <td>
        <a
          class="link-unstyled d-block margin-bottom-10"
          uiSref="app.content.messages.auto.edit"
          [uiParams]="{ messageId: message.id }"
        >
          <i
            *ngIf="!message.hasTest"
            class="cqi-sm"
            [ngClass]="MESSAGE_PART_TYPE_ICON_CLASSES[message.type]"
            [title]="'models.message.messagePartTypes.' + message.type | transloco"
          ></i>
          <i
            *ngIf="message.hasTest"
            class="cqi-sm cqi-ab-test"
            title="{{ 'autoMessages.table.table.body.abTest' | transloco }}"
          ></i>
          <span
            class="text-break"
            [title]="message.name"
            >{{ message.name }}</span
          >
        </a>
        <div
          *ngIf="isMessageHaveDeletedTags(message.filters.filters.tags)"
          class="margin-bottom-10"
        >
          <i class="cqi-sm cqi-triangle-exclamation text-danger"></i>
          <span class="text-muted">{{ 'autoMessages.table.table.body.name.messageHasDeletedTag' | transloco }}</span>
        </div>
        <div
          class="d-inline small text-muted"
          [ngClass]="{ 'dashed cursor-pointer': isMessageTriggersPopoverEnabled(message) }"
          [ngbPopover]="triggersPopover"
          [disablePopover]="!isMessageTriggersPopoverEnabled(message)"
          popoverClass="triggers-popover"
          placement="bottom-left"
        >
          <span>{{ 'autoMessages.table.table.body.name.condition' | transloco }}</span>

          <!-- События из триггеров -->
          <span
            *ngIf="message.triggers.length >= 1"
            class="text-break"
          >
            <i
              *ngIf="isMessageEventTriggerRemoved(message.triggers[0])"
              class="cqi-sm cqi-triangle-exclamation text-danger"
              [ngbTooltip]="'autoMessages.table.table.body.name.deletedEventTooltip' | transloco"
              container="body"
              placement="top"
            ></i>
            <span
              class="inline"
              [ngClass]="{ 'text-muted': isMessageEventTriggerRemoved(message.triggers[0]) }"
              >{{ getMessageEventTriggerPrettyName(message.triggers[0]) }}</span
            >
          </span>

          <!-- Открыл страницу -->
          <span
            *ngIf="isMessageHaveOpenedPageTriggerTypes(message.triggerTypes.openedWebPageTriggers)"
            class="inline text-break"
            >{{ 'autoMessages.table.triggerTypes.openedWebPage.nameShort' | transloco }}</span
          >

          <span
            *ngIf="isMessageHaveOpenedPageTriggerTypes(message.triggerTypes.openedSdkPageTriggers)"
            class="inline text-break"
            >{{ 'autoMessages.table.triggerTypes.openedSdkPage.nameShort' | transloco }}</span
          >

          <!-- Попытка ухода с сайта -->
          <span
            *ngIf="message.triggerTypes.leaveSiteAttemptTrigger"
            class="inline text-break"
            >{{ 'chatBot.list.triggerTypes.leaveSiteAttemptTrigger' | transloco }}</span
          >

          <span *ngIf="isMessageTriggersPopoverEnabled(message)">{{
            'autoMessages.table.table.body.name.andOtherSettings' | transloco
          }}</span>
        </div>
        <!--СОЗДАНО-->
        <div class="small text-muted margin-bottom-10">
          <span>{{
            'autoMessages.table.table.body.name.created'
              | transloco: { createdDate: (message.created | amDateFormat: 'LLL') }
          }}</span>
        </div>
        <div class="flex margin-between-cols-10">
          <!--СТАТУС-->
          <span
            *ngIf="message.active"
            class="message-status label label-light-success"
          >
            <img
              class="message-status-icon"
              src="assets/img/default/auto-messages/table/active.svg"
            />
            <span class="message-status-text">{{
              'autoMessages.table.table.body.name.messageStatus.active' | transloco
            }}</span>
          </span>
          <span
            *ngIf="!message.active"
            class="message-status label label-light-danger"
          >
            <img
              class="message-status-icon"
              src="assets/img/default/auto-messages/table/paused.svg"
            />
            <span class="message-status-text">{{
              'autoMessages.table.table.body.name.messageStatus.paused' | transloco
            }}</span>
          </span>
          <!--УВЕДОМЛЕНИЕ О БЛОКИРОВАНИИ EMAIL РАССЫЛКИ-->
          <span
            *ngIf="isEmailBlockedDueToSuspiciousActivity(message)"
            class="label label-danger"
            [ngbTooltip]="'autoMessages.table.table.body.name.blockedMessageTooltip.suspiciousActivity' | transloco"
            container="body"
            >{{ 'autoMessages.table.table.body.name.messageStatus.blocked' | transloco }}</span
          >
          <!--ПАПКА-->
          <span
            *ngIf="message.directory.name !== PSEUDO_DIRECTORY_TYPES.WITHOUT_DIRECTORY"
            class="label label-light-secondary text-truncate message-directory-name"
            [title]="message.directory.prettyName"
            >{{ message.directory.prettyName }}</span
          >
        </div>
      </td>

      <!--ПОСЛЕДНЯЯ ОТПРАВКА-->
      <td>
        <div *ngIf="message.lastSent">{{ message.lastSent | amDateFormat: 'LLL' }}</div>
        <div
          *ngIf="!message.lastSent"
          class="text-muted"
        >
          {{ 'autoMessages.table.table.body.notSended' | transloco }}
        </div>
      </td>

      <!--ОТПРАВЛЕНО-->
      <td>
        <h4 class="no-margin-bottom">{{ message.statistics.sended }}</h4>
      </td>

      <!--ПРОЧИТАЛИ-->
      <td>
        <cq-static-progressbar
          *ngIf="message.type !== MESSAGE_PART_TYPES.TELEGRAM; else tgReadStats"
          class="position-relative progress-statistic cursor-default"
          [max]="message.statistics.sended"
          [value]="message.statistics.read"
        >
          <div class="fade statistic-percent">
            {{ message.statistics.read / message.statistics.sended | accountingPercent }}
          </div>
          <div class="position-absolute top-0 fade statistic-count">{{ message.statistics.read }}</div>
        </cq-static-progressbar>
        <ng-template #tgReadStats>
          <span class="text-muted">–</span>
        </ng-template>
      </td>

      <!--ОТВЕТИЛИ-->
      <td>
        <cq-static-progressbar
          class="position-relative progress-statistic cursor-default"
          [max]="message.statistics.sended"
          [value]="message.statistics.replied"
        >
          <div class="fade statistic-percent">
            {{ message.statistics.replied / message.statistics.sended | accountingPercent }}
          </div>
          <div class="position-absolute top-0 fade statistic-count">{{ message.statistics.replied }}</div>
        </cq-static-progressbar>
      </td>

      <!--ПЕРЕШЛИ ПО ССЫЛКЕ-->
      <td class="d-none d-xl-table-cell">
        <div class="flex align-items-center">
          <cq-static-progressbar
            class="position-relative progress-statistic cursor-default"
            [max]="message.statistics.sended"
            [value]="message.statistics.clicked"
          >
            <div class="fade statistic-percent">
              {{ message.statistics.clicked / message.statistics.sended | accountingPercent }}
            </div>
            <div class="position-absolute top-0 fade statistic-count">{{ message.statistics.clicked }}</div>
          </cq-static-progressbar>
          <i
            *ngIf="
              message.type === MESSAGE_PART_TYPES.BLOCK_POPUP_BIG ||
              message.type === MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL
            "
            class="cqi-sm cqi-question-circle margin-left-10"
            [ngbTooltip]="'autoMessages.table.table.body.blockPopupsTipTooltip' | transloco"
          ></i>
        </div>
      </td>

      <!--ОТПИСАЛИСЬ-->
      <td class="d-none d-xl-table-cell">
        <cq-static-progressbar
          class="position-relative progress-statistic cursor-default"
          [max]="message.statistics.sended"
          [value]="message.statistics.unsubscribed"
        >
          <div class="fade statistic-percent">
            {{ message.statistics.unsubscribed / message.statistics.sended | accountingPercent }}
          </div>
          <div class="position-absolute top-0 fade statistic-count">{{ message.statistics.unsubscribed }}</div>
        </cq-static-progressbar>
      </td>

      <!--ДОСТИГНУТО ЦЕЛЕЙ-->
      <td>
        <cq-static-progressbar
          class="position-relative progress-statistic cursor-default"
          [max]="message.statistics.sended"
          [value]="message.statistics.goalCompleted"
        >
          <div class="fade statistic-percent">
            {{ message.statistics.goalCompleted / message.statistics.sended | accountingPercent }}
          </div>
          <div class="position-absolute top-0 fade statistic-count">{{ message.statistics.goalCompleted }}</div>
        </cq-static-progressbar>
      </td>

      <!--КНОПКИ-->
      <td class="shrink-by-content">
        <!-- Использование статистики без ограничений -->
        <a
          class="btn btn-borderless-primary"
          (click)="trackClickOnOpenStatistics()"
          uiSref="app.content.messages.auto.statistics"
          [uiParams]="{ messageId: message.id }"
          title="{{ 'autoMessages.table.table.body.statisticsButton.title' | transloco }}"
        >
          <i class="btn-icon cqi-sm cqi-chart-bar"></i>
        </a>

        <div
          ngbDropdown
          class="d-inline-block"
          dropdownClass="dropdown"
        >
          <button
            type="button"
            class="btn btn-borderless-primary"
            ngbDropdownToggle
            (click)="onClickDropdown()"
          >
            <i class="btn-icon cqi-sm cqi-ellipsis"></i>
          </button>
          <div
            ngbDropdownMenu
            class="dropdown-menu-right"
          >
            <!--Активировать сообщение. Для телеги-->
            <ng-container *ngIf="message.type === 'telegram'">
              <ng-container
                *ngTemplateOutlet="activateTmp; context: { $implicit: accessToTelegramAutoMessages.hasAccess }"
              >
              </ng-container>
            </ng-container>
            <!--Активировать сообщение. Для НЕ телеги-->
            <ng-container *ngIf="message.type !== 'telegram'">
              <ng-container
                *ngTemplateOutlet="activateTmp; context: { $implicit: accessToSelectedAutoMessages.hasAccess }"
              >
              </ng-container>
            </ng-container>

            <!--Активировать сообщение. Шаблон-->
            <ng-template
              #activateTmp
              let-hasAccessToActivate
            >
              <ng-container *ngIf="!message.active && message.directory.name !== SYSTEM_DIRECTORIES.ARCHIVE">
                <!--Без ограничений-->
                <button
                  *ngIf="hasAccessToActivate; else activeMessageWithoutRestrictions"
                  class="dropdown-item"
                  href
                  type="button"
                  (click)="clickActivateButton.emit(message)"
                >
                  <i class="dropdown-item-icon-left cqi-sm cqi-play-o"></i>
                  <span>{{ 'autoMessages.table.table.body.messageActionsDropdown.activated' | transloco }}</span>
                </button>
                <!--С ограничением-->
                <ng-template #activeMessageWithoutRestrictions>
                  <button
                    class="dropdown-item"
                    type="button"
                    (click)="openSomeAutoMessageBillingModal(message)"
                  >
                    <cq-plan-capability-coin class="dropdown-item-icon-left"></cq-plan-capability-coin>
                    <span>{{ 'autoMessages.table.table.body.messageActionsDropdown.activated' | transloco }}</span>
                  </button>
                </ng-template>
              </ng-container>
            </ng-template>
            <!--Приостановить сообщение-->
            <button
              *ngIf="message.active && message.directory.name !== SYSTEM_DIRECTORIES.ARCHIVE"
              class="dropdown-item"
              type="button"
              (click)="clickPauseButton.emit(message)"
            >
              <i class="dropdown-item-icon-left cqi-sm cqi-pause"></i>
              <span>{{ 'autoMessages.table.table.body.messageActionsDropdown.pause' | transloco }}</span>
            </button>
            <!--Архивировать сообщение-->
            <button
              *ngIf="message.directory.name !== SYSTEM_DIRECTORIES.ARCHIVE"
              class="dropdown-item"
              type="button"
              (click)="clickArchiveButton.emit(message)"
            >
              <i class="dropdown-item-icon-left cqi-sm cqi-archive"></i>
              <span>{{ 'autoMessages.table.table.body.messageActionsDropdown.archive' | transloco }}</span>
            </button>
            <!--Восстановить сообщение-->
            <button
              *ngIf="message.directory.name === SYSTEM_DIRECTORIES.ARCHIVE"
              class="dropdown-item"
              type="button"
              (click)="clickReestablishButton.emit(message)"
            >
              <i class="dropdown-item-icon-left cqi-sm cqi-archive"></i>
              <span>{{ 'autoMessages.table.table.body.messageActionsDropdown.reestablish' | transloco }}</span>
            </button>
            <!--Создать копию-->
            <button
              class="dropdown-item"
              type="button"
              (click)="clickCopyButton.emit(message)"
            >
              <i class="dropdown-item-icon-left cqi-sm cqi-copy"></i>
              <span>{{ 'autoMessages.table.table.body.messageActionsDropdown.createCopy' | transloco }}</span>
            </button>
            <!--Переименовать-->
            <button
              class="dropdown-item"
              type="button"
              (click)="clickRenameButton.emit(message)"
            >
              <i class="dropdown-item-icon-left cqi-sm cqi-pencil"></i>
              <span>{{ 'autoMessages.table.table.body.messageActionsDropdown.rename' | transloco }}</span>
            </button>
            <!--Переместить в папку-->
            <button
              *ngIf="message.directory.name !== SYSTEM_DIRECTORIES.ARCHIVE"
              class="dropdown-item"
              type="button"
              (click)="clickChangeDirectoryButton.emit(message)"
            >
              <i class="dropdown-item-icon-left cqi-sm cqi-move"></i>
              <span>{{ 'autoMessages.table.table.body.messageActionsDropdown.setDirectory' | transloco }}</span>
            </button>
          </div>
        </div>
      </td>

      <ng-template #triggersPopover>
        <div class="padding-top-5 padding-bottom-5">
          <div class="font-weight-bold margin-bottom-5">
            {{ 'autoMessages.table.triggersPopover.heading' | transloco }}
          </div>
          <!-- Триггеры -->
          <div
            class="margin-bottom-5"
            *ngFor="let trigger of message.triggers"
          >
            <i
              *ngIf="isMessageEventTriggerRemoved(trigger)"
              class="cqi-sm cqi-triangle-exclamation text-danger"
              [ngbTooltip]="'autoMessages.table.triggersPopover.deletedEventTooltip' | transloco"
              container="body"
              placement="top"
            ></i>
            <span class="inline text-secondary">{{ getMessageEventTriggerPrettyName(trigger) }}</span>
          </div>

          <!-- Новые триггеры (Открыл страницу)-->
          <div *ngIf="isMessageHaveOpenedPageTriggerTypes(message.triggerTypes.openedWebPageTriggers)">
            <div class="text-secondary">
              {{ 'autoMessages.table.triggerTypes.openedWebPage.name' | transloco }}
            </div>
            <div *ngFor="let openedPageTriggerType of message.triggerTypes.openedWebPageTriggers">
              <span class="inline text-secondary">{{
                getOpenedWebPageComparisonPrettyName(openedPageTriggerType.comparison) + ':'
              }}</span>
              <span class="inline text-secondary">{{ openedPageTriggerType.url }}</span>
            </div>
          </div>

          <!-- Новые триггеры (Открыл страницу)-->
          <div *ngIf="isMessageHaveOpenedPageTriggerTypes(message.triggerTypes.openedSdkPageTriggers)">
            <div class="text-secondary">
              {{ 'autoMessages.table.triggerTypes.openedSdkPage.name' | transloco }}
            </div>
            <div *ngFor="let openedPageTriggerType of message.triggerTypes.openedSdkPageTriggers">
              <span class="inline text-secondary">{{
                getOpenedSdkPageComparisonPrettyName(openedPageTriggerType.comparison) + ':'
              }}</span>
              <span class="inline text-secondary">{{ openedPageTriggerType.sdkPage }}</span>
            </div>
          </div>

          <div *ngIf="isMessageHaveFilters(message) || isMessageHaveFiltersByUrl(message)">
            <hr />
            <div class="font-weight-bold margin-bottom-5">
              {{ 'autoMessages.table.triggersPopover.dispatchUrl.heading' | transloco }}
            </div>
          </div>

          <!-- Фильтры -->
          <div *ngIf="isMessageHaveFilters(message)">
            <div class="text-secondary">
              <span *ngIf="message.filters.type === 'and'">{{
                'autoMessages.table.triggersPopover.satisfies.allFilters' | transloco
              }}</span>
              <span *ngIf="message.filters.type === 'or'">{{
                'autoMessages.table.triggersPopover.satisfies.anyOfFilters' | transloco
              }}</span>
            </div>

            <div *ngFor="let filter of message.filters.filters.props; first as first">
              <div
                *ngIf="first"
                class="text-secondary"
              >
                {{ 'autoMessages.table.triggersPopover.properties' | transloco }}
              </div>
              <span class="text-secondary">{{ filter.userProp.prettyName }}</span>
              <cq-message-filters
                class="d-inline text-secondary"
                [filter]="filter"
              ></cq-message-filters>
            </div>

            <div *ngFor="let filter of message.filters.filters.events; first as first">
              <div
                *ngIf="first"
                class="text-secondary"
              >
                {{ 'autoMessages.table.triggersPopover.events' | transloco }}
              </div>
              <span class="text-secondary">{{ filter.prettyName }}</span>
              <cq-message-filters
                class="d-inline text-secondary"
                [filter]="filter"
              ></cq-message-filters>
            </div>

            <span
              class="no-space"
              *ngFor="let filter of message.filters.filters.tags; first as first; last as last"
            >
              <div
                *ngIf="first"
                class="mt-10 text-secondary"
              >
                {{ 'autoMessages.table.triggersPopover.tags' | transloco }}
              </div>
              <cq-user-tag [tag]="filter.tag"></cq-user-tag>
              <span *ngIf="!last">,&nbsp;</span>
            </span>
          </div>

          <!-- Ограничение по страницам -->
          <div *ngIf="isMessageHaveFiltersByUrl(message)">
            <div class="text-secondary">{{ 'autoMessages.table.triggersPopover.dispatchUrl.enabled' | transloco }}</div>
          </div>
        </div>
      </ng-template>
    </tr>
  </tbody>
</table>
