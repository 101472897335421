import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import moment from 'moment';

import { App } from '@http/app/app.model';
import { EventTypeModel } from '@http/event-type/event-type.model';
import { EMAIL_TYPES, MESSAGE_PART_TYPES } from '@http/message-part/message-part.constants';
import { PropertyModel } from '@http/property/property.model';
import {
  TriggerChainStep,
  TriggerChainStepAutoMessage,
  TriggerChainStepDelay,
  TriggerChainStepExit,
  TriggerChainStepFilter,
  TriggerChainStepReactionCheck,
  TriggerChainStepSendingConditions,
} from '@http/trigger-chain/internal-types';
import { TriggerChainEditorStore } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.store';
import { TRIGGER_CHAIN_BLOCK_TYPE } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import {
  BlockViewData,
  ExitBlockViewData,
  ReactionBlockViewData,
  TriggerChainViewStates,
} from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.types';
import { ELASTICSEARCH_OPERATION } from '@panel/app/services/elasticsearch-operation/elasticsearch-operation.constants';
import { EventFilter, PropertyFilter } from '@panel/app/services/filter/types/filter.internal-types';
import { TIME_UNIT_MEASURES } from '@panel/app-old/shared/services/time-unit/time-unit.constants';

/**
 * Сервис-парсер для работы с данными для view шагов
 */
@Injectable()
export class BlockViewParser {
  constructor(
    private readonly transloco: TranslocoService,
    private readonly triggerChainEditorStore: TriggerChainEditorStore,
    private readonly propertyModel: PropertyModel,
    private readonly eventTypeModel: EventTypeModel,
  ) {}

  /**
   * Возвращает данные для view
   */
  getParsedData(step: TriggerChainStep, states?: Partial<TriggerChainViewStates>): BlockViewData {
    switch (step.type) {
      case 'autoMessage':
        return this.getDataForTriggerMessageBlockView(step, states);
      case 'delay':
        return this.getDataForDelayBlockView(step, states);
      case 'filter':
        return this.getDataForFilterBlockView(step, states);
      case 'sendingConditions':
        return this.getDataForSendingConditionBlockView(step, states);
      case 'reaction':
        return this.getDataForReactionBlockView(step, states);
      case 'exit':
        return this.getDataForExitBlockView(step, states);
      default:
        throw new Error('This is case not handled');
    }
  }

  /**
   * Возвращает данные для view по сообщению в шаге
   */
  private getDataForTriggerMessageBlockView(
    step: TriggerChainStepAutoMessage,
    states?: Partial<TriggerChainViewStates>,
  ): BlockViewData {
    const { uuid, coordinates, name, meta } = step;
    const part = meta.message.parts[0];

    let type: TRIGGER_CHAIN_BLOCK_TYPE;
    let isContentEmpty: boolean;

    switch (part.type) {
      case MESSAGE_PART_TYPES.EMAIL:
        type = TRIGGER_CHAIN_BLOCK_TYPE.EMAIL;
        const emailPart = part[part.type];
        isContentEmpty =
          !emailPart[EMAIL_TYPES.DEFAULT].body &&
          !emailPart[EMAIL_TYPES.BEE].bodyJson &&
          !emailPart[EMAIL_TYPES.HTML].body;
        break;
      case MESSAGE_PART_TYPES.POPUP_CHAT:
        type = TRIGGER_CHAIN_BLOCK_TYPE.CHAT;
        isContentEmpty = !part[part.type].body;
        break;
      case MESSAGE_PART_TYPES.BLOCK_POPUP_BIG:
      case MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL:
        type = TRIGGER_CHAIN_BLOCK_TYPE.POPUP;
        isContentEmpty = !part[part.type].bodyJson.blocks[0][0].length;
        break;
      case MESSAGE_PART_TYPES.TELEGRAM:
        type = TRIGGER_CHAIN_BLOCK_TYPE.TELEGRAM_MESSAGE;
        const bodyJson = part[part.type].bodyJson;
        isContentEmpty = bodyJson.contents.length === 0 || !bodyJson.contents[0].value;
        break;
      case MESSAGE_PART_TYPES.SDK_PUSH:
        type = TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH;
        isContentEmpty = part[part.type].body.length === 0;
        break;
      case MESSAGE_PART_TYPES.WEBHOOK:
        type = TRIGGER_CHAIN_BLOCK_TYPE.WEBHOOK;
        isContentEmpty = part[part.type].body.length === 0;
        break;
      default:
        throw new Error('This is case not handled');
    }

    return {
      type,
      uuid: uuid,
      coordinates: coordinates,
      name: name,
      isContentEmpty,
      previewImage: {
        ...step.meta.previewImage,
      },
      nextStepConnected: !!step.meta.nextStep,
      ...this.getStates(states),
    };
  }

  private getDataForSendingConditionBlockView(
    step: TriggerChainStepSendingConditions,
    states?: Partial<TriggerChainViewStates>,
  ): BlockViewData {
    let additionalSendingConditionNumber: number = 0;
    let firstTriggerName: string = '';

    const { meta: conditions } = step;

    let sendingConditionNumber = 0;
    switch (true) {
      case conditions.triggers.length >= 1:
        sendingConditionNumber =
          conditions.triggers.length +
          conditions.filters.filters.events.length +
          conditions.filters.filters.props.length;

        firstTriggerName = this.getEventTypeNameById(conditions.triggers[0]!);
        break;
      case conditions.triggerTypes.openedWebPageTriggers.length > 0:
        sendingConditionNumber =
          conditions.triggerTypes.openedWebPageTriggers.length +
          conditions.filters.filters.events.length +
          conditions.filters.filters.props.length;

        firstTriggerName = this.transloco.translate(
          'triggerChainBlock.parser.sendingConditions.triggers.openedWebPage',
        );
        break;
      case conditions.triggerTypes.openedSdkPageTriggers.length > 0:
        sendingConditionNumber =
          conditions.triggerTypes.openedSdkPageTriggers.length +
          conditions.filters.filters.events.length +
          conditions.filters.filters.props.length;

        firstTriggerName = this.transloco.translate(
          'triggerChainBlock.parser.sendingConditions.triggers.openedSdkPage',
        );
        break;
      case conditions.triggerTypes.leaveSiteAttemptTrigger:
        sendingConditionNumber = 1 + conditions.filters.filters.events.length + conditions.filters.filters.props.length;

        firstTriggerName = this.transloco.translate(
          'triggerChainBlock.parser.sendingConditions.triggers.leaveSiteAttempt',
        );
        break;
      default:
        throw new Error('Unknown type of trigger');
    }

    additionalSendingConditionNumber = sendingConditionNumber > 1 ? sendingConditionNumber - 1 : 0;

    return {
      type: TRIGGER_CHAIN_BLOCK_TYPE.SENDING_CONDITION,
      coordinates: step.coordinates,
      name: step.name,
      uuid: step.uuid,
      firstTriggerName,
      additionalSendingConditionNumber,
      nextStepConnected: !!step.meta.nextStep,
      ...this.getStates(states),
    };
  }

  /**
   * TODO: Куча копипасты ниже образовалось в результате рефакторинга, решил не доправлять сейчас,
   *   чтоб не растягивать. Можно поправить в любой момент, не критично
   */
  private getDataForFilterBlockView(
    step: TriggerChainStepFilter,
    states?: Partial<TriggerChainViewStates>,
  ): BlockViewData {
    const parsePropertyFilter = (filter: PropertyFilter): string => {
      let text: string;

      if (!filter.propertyName) {
        throw new Error('Property name is undefined');
      }

      let propertyName: string = this.propertyModel.parseUserPropertyName(filter.propertyName);
      let operationTypeName: string = this.transloco.translate(
        `elasticsearchOperationModel.operation.${filter.operation.type}`,
      );

      switch (filter.operation.type) {
        case ELASTICSEARCH_OPERATION.DAYS_MORE_THAN:
        case ELASTICSEARCH_OPERATION.DAYS_LESS_THAN:
        case ELASTICSEARCH_OPERATION.DAYS_MORE_THAN_OR_UNKNOWN:
        case ELASTICSEARCH_OPERATION.DAYS_LESS_THAN_OR_UNKNOWN:
          text = this.transloco.translate(`triggerChainBlock.parser.filter.date.${filter.operation.value.units}`, {
            name: propertyName,
            operationTypeName,
            value: filter.operation.value.value,
          });
          break;
        case ELASTICSEARCH_OPERATION.NUMBER_RANGE:
          text = this.transloco.translate('triggerChainBlock.parser.filter.range', {
            name: propertyName,
            from: filter.operation.value.value1,
            to: filter.operation.value.value2,
          });
          break;
        case ELASTICSEARCH_OPERATION.KNOWN:
        case ELASTICSEARCH_OPERATION.UNKNOWN:
        case ELASTICSEARCH_OPERATION.BOOLEAN_TRUE:
        case ELASTICSEARCH_OPERATION.BOOLEAN_FALSE:
          text = `${propertyName} - ${operationTypeName}`;
          break;
        default:
          if (!filter.operation.value || !filter.operation.value.value) {
            text = `${propertyName} - ${operationTypeName}`;
            break;
          }

          let value = filter.operation.value.value;
          if (filter.propertyName === '$telegram_subscriptions') {
            const selectedIntegrationsIds = (value as string).split(',');

            value = this.triggerChainEditorStore.telegramIntegrations$
              .getValue()
              .filter((integration) => {
                return selectedIntegrationsIds.includes(integration.settings.botId);
              })
              .map((i) => `@${i.settings.botName}`)
              .join(', ');
          }

          text = `${propertyName} - ${operationTypeName} - ${value}`;
          break;
      }

      return text;
    };

    const parseEventFilter = (filter: EventFilter): string => {
      let text: string;

      if (!filter.eventId) {
        throw new Error('Event name is undefined');
      }

      // ЭТО КОСТЫЛЬ И ДОЛЖЕН БЫТЬ УДАЛЕН
      let eventName = this.triggerChainEditorStore.eventTypes$
        .getValue()
        .find((eventType) => eventType.id === filter.eventId)?.name;

      if (!eventName) {
        console.log(`${step.name} содержит нерабочие фильтры`);
      }

      eventName = eventName ?? filter.eventId;

      //const eventName = this.getEventTypeNameById(filter.eventId);

      // КОНЕЦ КОСТЫЛЯ, УДАЛИТЬ ВСЕ , КРОМЕ ЗАКОМЕНЧЕНОЙ СТРОКИ, ЕЁ РАСКОМЕНТИТЬ

      let operationTypeName: string = this.transloco.translate(
        `elasticsearchOperationModel.operation.${filter.operation.type}`,
      );

      switch (filter.operation.type) {
        case ELASTICSEARCH_OPERATION.DAYS_MORE_THAN:
        case ELASTICSEARCH_OPERATION.DAYS_LESS_THAN:
        case ELASTICSEARCH_OPERATION.DAYS_MORE_THAN_OR_UNKNOWN:
        case ELASTICSEARCH_OPERATION.DAYS_LESS_THAN_OR_UNKNOWN:
          text = this.transloco.translate(`triggerChainBlock.parser.filter.date.${filter.operation.value.units}`, {
            name: eventName,
            operationTypeName,
            value: filter.operation.value.value,
          });
          break;
        case ELASTICSEARCH_OPERATION.NUMBER_RANGE:
          text = this.transloco.translate('triggerChainBlock.parser.filter.range', {
            name: eventName,
            from: filter.operation.value.value1,
            to: filter.operation.value.value2,
          });
          break;
        case ELASTICSEARCH_OPERATION.KNOWN:
        case ELASTICSEARCH_OPERATION.UNKNOWN:
        case ELASTICSEARCH_OPERATION.BOOLEAN_TRUE:
        case ELASTICSEARCH_OPERATION.BOOLEAN_FALSE:
          text = `${eventName} - ${operationTypeName}`;
          break;
        default:
          if (filter.operation.value && filter.operation.value.value) {
            text = `${eventName} - ${operationTypeName} - ${filter.operation.value.value}`;
          } else {
            text = `${eventName} - ${operationTypeName}`;
          }
      }

      return text;
    };

    const filters = step.meta.filters.filters;

    return {
      type: TRIGGER_CHAIN_BLOCK_TYPE.FILTER,
      coordinates: step.coordinates,
      name: step.name,
      uuid: step.uuid,
      logicalOperation: step.meta.filters.logicalOperation,
      eventFilters: hasEventFilters(filters.events) ? filters.events.map((e) => parseEventFilter(e)) : [],
      propFilters: hasPropertyFilters(filters.props) ? filters.props.map((p) => parsePropertyFilter(p)) : [],
      nextStepConnected: !!step.meta.nextStep,
      nextStepOnFailConnected: !!step.meta.nextStepOnFail,
      ...this.getStates(states),
    };

    function hasEventFilters(filters: EventFilter[]): boolean {
      return filters[0]?.eventId !== null;
    }

    function hasPropertyFilters(filters: PropertyFilter[]): boolean {
      return filters[0]?.propertyName !== null;
    }
  }

  private getDataForReactionBlockView(
    step: TriggerChainStepReactionCheck,
    states?: Partial<TriggerChainViewStates>,
  ): ReactionBlockViewData {
    const timeByUnit = Math.floor(step.meta.reactionTime.time / TIME_UNIT_MEASURES[step.meta.reactionTime.unit]);
    const reactionTimeText = this.transloco.translate(
      `services.timeUnit.unitsWithValue.${step.meta.reactionTime.unit}.fullAlt`,
      {
        count: timeByUnit,
      },
    );

    return {
      type: TRIGGER_CHAIN_BLOCK_TYPE.REACTION,
      uuid: step.uuid,
      coordinates: step.coordinates,
      name: step.name,
      reactionTimeText,
      eventName: step.meta.event ? this.getEventTypeNameById(step.meta.event) : null,
      nextStepConnected: !!step.meta.nextStep,
      nextStepOnFailConnected: !!step.meta.nextStepOnFail,
      reactionType: step.meta.reactionType,
      ...this.getStates(states),
    };
  }

  private getDataForDelayBlockView(
    step: TriggerChainStepDelay,
    states?: Partial<TriggerChainViewStates>,
  ): BlockViewData {
    let currentApp: App | null = this.triggerChainEditorStore.currentApp$.getValue();
    if (currentApp === null) {
      throw new Error('Current app is null');
    }

    let delayType: 'seconds' | 'date' | 'beforePropDate';
    let delayText;
    let failText = '';

    if (step.meta.waitForDate !== null) {
      delayType = 'date';

      let date = moment.unix(step.meta.waitForDate);

      delayText = this.transloco.translate(`services.timeUnit.date.dateWithHMandTimezone`, {
        date: date.format('L'),
        hhmm: date.format('HH:mm'),
        timezone: currentApp.settings.timezone,
      });
      failText = this.transloco.translate('triggerChainBlock.views.delay.failOutlineWaitForDate');
    } else if (step.meta.propertyName) {
      delayType = 'beforePropDate';

      delayText = this.transloco.translate('services.timeUnit.beforePropDate', {
        date: this.transloco.translate(`services.timeUnit.unitsWithValue.${step.meta.delay.unit}.fullAlt`, {
          count: Math.floor(step.meta.delay.time / TIME_UNIT_MEASURES[step.meta.delay.unit]),
        }),
        prop: this.propertyModel.parseUserPropertyName(step.meta.propertyName),
      });
      failText = this.transloco.translate('triggerChainBlock.views.delay.failOutlineDelayBasedOnProperty');
    } else {
      delayType = 'seconds';

      delayText = this.transloco.translate(`services.timeUnit.unitsWithValue.${step.meta.delay.unit}.fullAlt`, {
        count: Math.floor(step.meta.delay.time / TIME_UNIT_MEASURES[step.meta.delay.unit]),
      });
    }

    return {
      type: TRIGGER_CHAIN_BLOCK_TYPE.DELAY,
      uuid: step.uuid,
      coordinates: step.coordinates,
      name: step.name,
      delayText,
      delayType,
      failText,
      nextStepConnected: !!step.meta.nextStep,
      nextStepOnFailConnected: !!step.meta.nextStepOnFail,
      ...this.getStates(states),
    };
  }

  private getEventTypeNameById(eventTypeId: string): string {
    const eventType = EventTypeModel.findEventTypeById(
      eventTypeId,
      this.triggerChainEditorStore.eventTypes$.getValue(),
    );

    return this.eventTypeModel.parseName(eventType.name);
  }

  private getStates(states?: Partial<TriggerChainViewStates>): TriggerChainViewStates {
    return {
      contentValid: states?.contentValid ?? true,
      connectionsValid: states?.connectionsValid ?? true,
      incomingConnectionsValid: states?.incomingConnectionsValid ?? true,
      selected: states?.selected ?? false,
      hoveredValid: states?.hoveredValid ?? false,
      hoveredInvalid: states?.hoveredInvalid ?? false,
    };
  }

  private getDataForExitBlockView(
    step: TriggerChainStepExit,
    states?: Partial<TriggerChainViewStates>,
  ): ExitBlockViewData {
    return {
      type: step.meta.type === 'success' ? TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS : TRIGGER_CHAIN_BLOCK_TYPE.EXIT,
      uuid: step.uuid,
      coordinates: step.coordinates,
      name: step.name,
      ...this.getStates(states),
    };
  }
}
