import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import {
  AutoMessageTriggerType,
  OpenedSdkPageTriggerType,
  OpenedWebPageTriggerType,
} from '@http/message/trigger.types';
import { EventType } from '@http/property/property.model';
import { TriggerChain, TriggerChainStep } from '@http/trigger-chain/internal-types';
import { TriggerChainEditorStore } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.store';
import { IBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.interfaces';
import { FiltersAJS } from '@panel/app/services/filter-ajs/filter-ajs.types';

@Component({
  selector: 'cq-tc-sending-condition-overlay',
  templateUrl: './tc-sending-condition-overlay.component.html',
  styleUrls: ['./tc-sending-condition-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TcSendingConditionOverlayComponent implements AfterViewInit {
  @Input()
  @HostBinding('style.width.px')
  width: number = 0;

  @Input()
  @HostBinding('style.height.px')
  height: number = 0;

  @Input()
  @HostBinding('style.left.px')
  x = 0;

  @Input()
  @HostBinding('style.top.px')
  y = 0;

  @ViewChild(NgbPopover)
  popover!: NgbPopover;

  get eventTypes() {
    if (this.sendingCondition.type === 'sendingConditions') {
      let triggers = this.sendingCondition.meta.triggers;

      return this.triggerChainEditorStore.eventTypes$.getValue().filter((eventType) => triggers.includes(eventType.id));
    }

    return null;
  }

  get filters(): FiltersAJS | null {
    if (this.sendingCondition.type === 'sendingConditions') {
      return this.sendingCondition.meta.filters;
    }

    return null;
  }

  _overlaySource?: IBlockView;
  set overlaySource(value: IBlockView) {
    this._overlaySource = value;
  }
  get overlaySource(): IBlockView {
    if (!this._overlaySource) {
      throw new Error('Could not find overlay source');
    }

    return this._overlaySource;
  }

  get triggerChain(): TriggerChain {
    let chain = this.triggerChainEditorStore.triggerChain$.getValue();
    if (chain === null) {
      throw new Error('Trigger chain is null');
    }

    return chain;
  }

  get triggerTypes(): AutoMessageTriggerType | null {
    if (this.sendingCondition.type === 'sendingConditions') {
      return this.sendingCondition.meta.triggerTypes;
    }

    return null;
  }

  get sendingCondition(): TriggerChainStep {
    let step = this.triggerChain.steps.find((step) => step.type === 'sendingConditions');
    if (step === undefined) {
      throw new Error('Sending condition step is undefined');
    }

    return step;
  }

  constructor(
    private readonly translocoService: TranslocoService,
    private readonly triggerChainEditorStore: TriggerChainEditorStore,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    this.popover.open();
    this.cdr.markForCheck();
  }

  getChainEventTriggerPrettyName(event: EventType): string {
    if (this.isEventRemoved(event)) {
      return this.translocoService.translate('general.deletedEvent');
    }

    return event.prettyName;
  }

  getOpenedWebPageComparisonPrettyName(comparison: OpenedWebPageTriggerType['comparison']): string {
    return this.translocoService.translate(`autoMessages.table.triggerTypes.openedWebPage.${comparison}`);
  }

  getOpenedSdkPageComparisonPrettyName(comparison: OpenedSdkPageTriggerType['comparison']): string {
    return this.translocoService.translate(`autoMessages.table.triggerTypes.openedSdkPage.${comparison}`);
  }

  isEventRemoved(eventType: EventType): boolean {
    if (!eventType.id) {
      return true;
    }

    return !eventType.visible || typeof eventType === undefined;
  }

  trackByTriggers(index: number, trigger: EventType): string {
    return trigger.id;
  }

  trackByOpenedWebPageTriggers(index: number, openedPageTriggerType: OpenedWebPageTriggerType): string {
    return openedPageTriggerType.localId;
  }

  trackByOpenedSdkPageTriggers(index: number, openedPageTriggerType: OpenedSdkPageTriggerType): string {
    return openedPageTriggerType.localId;
  }
}
