<ng-container *transloco="let t; read: 'triggerChainStepNameEditorComponent'">
  <div class="d-flex align-items-center justify-content-start justify-space-between w-100">
    <!-- В span вставляется текст для вычисления ширины input -->
    <span
      class="hidden-layer-input"
      #NameHideLayer
      [textContent]="control.value || t('placeholder')"
    >
    </span>
    <div [style.width.px]="NameHideLayer.clientWidth">
      <input
        #nameInput
        type="text"
        class="name-input form-control no-border p-0"
        (blur)="isNameFieldFocused = false"
        (focus)="isNameFieldFocused = true"
        [formControl]="control"
        placeholder="{{ t('placeholder') }}"
      />
      <cq-validation-messages
        position="bottom"
        [control]="control"
      >
        <cq-validation-message errorName="required">
          {{ t('errors.required') }}
        </cq-validation-message>
        <cq-validation-message errorName="maxlength">
          {{ t('errors.maxLength', { maxLength: STEP_NAME_MAX_LENGTH }) }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>

    <button
      class="btn btn-borderless-primary"
      type="button"
      [ngClass]="{ focus: this.isNameFieldFocused }"
      (click)="nameInput.focus()"
      [ngbTooltip]="t('renameButton.tooltip')"
      container="body"
    >
      <i class="btn-icon cqi-sm cqi-pencil"></i>
    </button>
  </div>
</ng-container>
