import { HashMap, TranslocoService } from '@jsverse/transloco';
import { Renderer } from '@pixi/core';
import { Container } from 'pixi.js';

import { CanvasRenderService } from '@panel/app/services/canvas/common/render/canvas-render.service';
import { TextStyleForRender } from '@panel/app/services/canvas/common/render/canvas-render.types';
import { AbsBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.view';
import {
  TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX,
  TRIGGER_CHAIN_BLOCK_TYPE,
} from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { FilterBlockViewData } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.types';
import { AtlasTexture, ElementBuilder } from '@panel/app/services/canvas/tirgger-chain/builders';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';
import { InteractiveBlockPartViewFactory } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.factory';
import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';
import { UNICODE_ICON } from '@panel/app/shared/constants/unicode-icon.constants';

export class FilterView extends AbsBlockView<TRIGGER_CHAIN_BLOCK_TYPE.FILTER> {
  constructor(
    data: FilterBlockViewData,
    interactiveBlockPartViewFactory: InteractiveBlockPartViewFactory,
    transloco: TranslocoService,
    renderer: Renderer,
  ) {
    super(data, interactiveBlockPartViewFactory, transloco, renderer);
  }

  getTitleIconBackgroundTextureIfDefault(): AtlasTexture {
    return 'circle-background-white-15';
  }

  getTitleIconBackgroundTextureIfSelected(): AtlasTexture {
    return 'circle-background-primary600-15';
  }

  getTitleIconBorderTextureIfDefault(): AtlasTexture | undefined {
    return undefined;
  }

  getTitleIconBorderTextureIfSelected(): AtlasTexture | undefined {
    return undefined;
  }

  getTitleIconBorderTextureIfIncomingConnectionsInvalid(): AtlasTexture {
    return 'circle-border-danger600-15';
  }

  getTitleIconCode(): string {
    return UNICODE_ICON['cqi-user'];
  }

  getTitleIconColorIfDefault(): number {
    return HEX_COLOR.PRIMARY_600;
  }

  getTitleIconColorIfSelected(): number {
    return HEX_COLOR.WHITE;
  }

  renderContent(): Container {
    let elementsForRender: Container[] = [];

    let descriptionText: Container | null = null;
    let emptyFilters: Container | null = null;
    let filterList: Container | null = null;
    let filterMatch: Container | null = null;
    let filterNotMatch: Container | null = null;

    descriptionText = this.renderDescriptionText();

    if (this.data.propFilters.length + this.data.eventFilters.length === 0) {
      emptyFilters = this.renderEmptyFilter();
    } else {
      filterList = this.renderFilterList();
      filterMatch = this.renderFilterMatchInteractivePart();
      filterNotMatch = this.renderFilterNotMatchInteractivePart();
    }

    [descriptionText, emptyFilters, filterList, filterMatch, filterNotMatch].forEach((el) => {
      if (el) {
        elementsForRender.push(el);
      }
    });

    return ElementBuilder.wrapElementsInRectangle(elementsForRender, {
      placement: {
        flexDirection: 'column',
        marginBetweenRows: 10,
      },
    });
  }

  private renderDescriptionText(): Container {
    let text: string = this.t('description');
    let style: TextStyleForRender = {
      color: HEX_COLOR.GRAY_400,
      wordWrap: true,
      wordWrapWidth: TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX,
    };

    return CanvasRenderService.renderText(text, style);
  }

  private renderEmptyFilter(): Container {
    let text = this.renderEmptyFilterText();

    return ElementBuilder.wrapElementsInRectangle([text], {
      border: {
        sideTextureName: 'border-gray800-272-side',
        innerTextureName: 'border-gray800-272-inner',
      },
      placement: {
        flexDirection: 'column',
        padding: {
          x: 10,
          y: 10,
        },
      },
    });
  }

  private renderEmptyFilterText(): Container {
    let text: string = this.t('placeholder');
    let style: TextStyleForRender = {
      color: HEX_COLOR.GRAY_650,
      wordWrapWidth: TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX,
    };

    return CanvasRenderService.renderText(text, style);
  }

  private renderFilterTitleIcon(iconCode: string): Container {
    let style: TextStyleForRender = {
      fontSize: 15,
    };

    return CanvasRenderService.renderIcon(iconCode, style);
  }

  private renderSingleFilter(text: string): Container {
    let style: TextStyleForRender = {
      color: HEX_COLOR.GRAY_400,
      wordWrap: true,
      wordWrapWidth: 232,
    };
    return CanvasRenderService.renderText(text, style);
  }

  private renderFilterList(): Container {
    let filterItems: Container[] = [];

    this.data.propFilters.forEach((filter) => {
      let icon: Container = this.renderFilterTitleIcon(UNICODE_ICON['cqi-user-o']);
      const propFilters = this.renderSingleFilter(filter);

      const renderedFilterTypeAndName = ElementBuilder.wrapElementsInRectangle([icon, propFilters], {
        placement: {
          flexDirection: 'row',
          marginBetweenCols: 5,
        },
      });
      filterItems.push(renderedFilterTypeAndName);
    });

    this.data.eventFilters.forEach((filter) => {
      let icon: Container = this.renderFilterTitleIcon(UNICODE_ICON['cqi-cursor-arrow']);
      const eventFilter = this.renderSingleFilter(filter);

      const renderedFilterTypeAndName = ElementBuilder.wrapElementsInRectangle([icon, eventFilter], {
        placement: {
          flexDirection: 'row',
          marginBetweenCols: 5,
        },
      });
      filterItems.push(renderedFilterTypeAndName);
    });

    return ElementBuilder.wrapElementsInRectangle(filterItems, {
      border: {
        sideTextureName: 'border-gray800-272-side',
        innerTextureName: 'border-gray800-272-inner',
      },
      placement: {
        flexDirection: 'column',
        marginBetweenRows: 5,
        padding: { x: 10, y: 10 },
      },
    });
  }

  private renderFilterMatchInteractivePart(): Container {
    if (this.interactiveBlockParts.length === 0) {
      this.interactiveBlockPartViewFactory.create(INTERACTIVE_BLOCK_PART.FILTER_MATCH, this);
    } else {
      this.interactiveBlockParts[0].rerender();
    }

    return this.interactiveBlockParts[0].graphicContainer;
  }

  private renderFilterNotMatchInteractivePart(): Container {
    if (this.interactiveBlockParts.length === 1) {
      this.interactiveBlockPartViewFactory.create(INTERACTIVE_BLOCK_PART.FILTER_NOT_MATCH, this);
    } else {
      this.interactiveBlockParts[1].rerender();
    }

    return this.interactiveBlockParts[1].graphicContainer;
  }

  private t(key: string, params?: HashMap): string {
    return this.transloco.translate(`triggerChainBlock.views.filter.${key}`, params);
  }
}
