import { ChangeDetectorRef, Directive, HostBinding, Input, NgZone } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { EMPTY_ARRAY } from '@taiga-ui/cdk';
import { merge } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, skipWhile, take, takeUntil } from 'rxjs/operators';

import { DestroyService } from '@panel/app/services';

const hasErrorClassName = 'has-error';

@Directive({
  selector: '[cqShowError]',
  providers: [DestroyService],
})
export class ShowErrorDirective {
  @Input()
  cqShowError: AbstractControl | null = null;

  @Input()
  classList: readonly string[] = [hasErrorClassName];

  @HostBinding('class')
  get classes(): readonly string[] {
    return this.visible ? this.classList : EMPTY_ARRAY;
  }

  constructor(ngZone: NgZone, cdr: ChangeDetectorRef, destroy$: DestroyService) {
    ngZone.onStable
      .pipe(
        skipWhile(() => !this.cqShowError),
        // После того как появился control, нам по факту надо подменить один observable на другой
        // Поэтому от оригинального мы берем только один emit, чтоб mergeMap вызвался только один раз
        // Может быть есть интересней решеение, я хз
        take(1),
        mergeMap(() => {
          return merge(ngZone.onStable, this.cqShowError!.statusChanges);
        }),
      )
      .pipe(
        map(() => this.visible),
        distinctUntilChanged(),
        takeUntil(destroy$),
      )
      .subscribe(() => {
        cdr.detectChanges();
      });
  }

  get visible(): boolean {
    return !!this.cqShowError?.invalid && this.cqShowError.touched;
  }
}
